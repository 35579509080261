@tailwind base;
@tailwind components;
@tailwind utilities;

  .card-header {
    border-bottom: 1px solid #ddd; 
    padding-bottom: 10px;
  }
  
  .name {
    margin-bottom: 5px;
  } 
  
  .contact-list {
    list-style-type: none;
    padding: 0;
    margin: 10px 0; 
  }
  
  .contact-list li {
    margin-bottom: 5px;
  }
  
  /* Example: Add icons with Font Awesome or similar */
  .icon-phone, .icon-email, .icon-website { 
    margin-right: 8px;
  }
  
  .business-card {
    background: linear-gradient(to right, #f5f5f5, #e5e5e5); 
    padding: 15px;
    border-radius: 10px; 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
   }
   
   .business-card h3 {
     color: #333333; /* Dark gray for good contrast */
     font-weight: bold;
   }
   
   